<template>
    <div class="main-update-password mt-5">
        <div class="box max-w-400 mx-auto">
            <div class="text-center">
                <img class="w-50 my-4" src="https://pay.ecart.com/img/logo-negro.svg" alt="" />
            </div>
            <h6>Update password</h6>
            <div v-if="error" class="alert alert-danger d-flex align-items-center" role="alert">
                <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                    <use xlink:href="#exclamation-triangle-fill"/>
                </svg>
                <small>
                    {{ error }}
                </small>
            </div>
            <div class="mt-3">
                <form @submit.prevent="submit">
                    <form-password ref="formPassword" show-password-confirmation/>
                    <b-button type="submit" :disabled="loading || error" class="w-100 mb-2">
                        <custom-spinner v-if="loading" class="spinner-border-sm me-1" />
                        Continue
                    </b-button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomSpinner from '@/components/ui/CustomSpinner.vue';
import FormPassword from '@/components/forms/FormPassword.vue';
import { api } from '@/services';

export default {
    components: { CustomSpinner, FormPassword },

    data() {
        return {
            loading: false,
            error: null,
        };
    },

    created()  {
        const { _v } = this.$route.query;

        if (!_v) {
            this.error = 'The request is required to have the "_v" parameter.';
        }
    },

    methods: {
        async submit () {
            const formPassword = this.$refs['formPassword']
            const validated = await formPassword.validate();

            if (!validated) return;

            try {
                this.loading = true;
                await this.$axios.put('/_/admins/me', {
                    password: formPassword.formData.password
                });

                const token = this._getCookie('_tid');
                if (token) {
                    await api.logout();
                    this._deleteCookie('_tid');
                }

                this.$router.push('/login');
                this.$toast.success('Password updated successfully');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
.main-update-password {
    margin: 0 auto;
}
</style>
